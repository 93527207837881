import React from 'react';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import CardWithIcon from './CardWithIcon';

const ContentWidget = ({contents, free, premium, exclusive}) => {
    return (
        <CardWithIcon
            to="/"
            icon={PermMediaIcon}
            title="Contents"
            subtitle={contents}
        >
            <ul><li>Free: {free}</li><li>Premium: {premium}</li><li>Exclusive: {exclusive}</li></ul>
        </CardWithIcon>
    );
};

export default ContentWidget;