import baseUrl from './config'

const uploadImage = requestHandler => (type, resource, params) => {
  if (type === "CREATE" && resource === "focus") {
    const token = localStorage.getItem("token");
    let data = params.data;
    let form = new FormData();
    form.append("image", data.focus_image_url.rawFile);
    form.append("focus", data.focus);
    form.append("focus_display_name", data.focus_display_name);
    form.append("focus_display_order", data.focus_display_order);
    return fetch(`${baseUrl}/api/admin/focus`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: form
    })
      .then(response => response.json())
      .then(json => ({ data: { id: json.id } }));
  }
  if (type === "UPDATE" && resource === "focus") {
    const token = localStorage.getItem("token");
    let data = params.data;
    let form = new FormData();
    
    if (data.focus_image_url.rawFile) {
      form.append("image", data.focus_image_url.rawFile);
    }
    form.append("focus", data.focus);
    form.append("focus_display_name", data.focus_display_name);
    form.append("focus_display_order", data.focus_display_order);
    return fetch(`${baseUrl}/api/admin/focus/${data.id}`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: form
    })
      .then(response => response.json())
      .then(json => ({ data: { id: json.id } }));
  }
  if (type === "CREATE" && resource === "testimonials") {
    const token = localStorage.getItem("token");
    let data = params.data;
    let form = new FormData();
    form.append("image", data.t_image_url.rawFile);
    form.append("t_name", data.t_name);
    form.append("t_message", data.t_message);
    form.append("t_slide_order", data.t_slide_order);
    return fetch(`${baseUrl}/api/admin/testimonials`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: form
    })
      .then(response => response.json())
      .then(json => ({ data: { id: json.id } }));
  }
  if (type === "UPDATE" && resource === "testimonials") {
    const token = localStorage.getItem("token");
    let data = params.data;
    let form = new FormData();
    form.append("image", data.t_image_url.rawFile);
    form.append("t_name", data.t_name);
    form.append("t_message", data.t_message);
    form.append("t_slide_order", data.t_slide_order);
    return fetch(`${baseUrl}/api/admin/testimonials`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: form
    })
      .then(response => response.json())
      .then(json => ({ data: { id: json.id } }));
  }
  return requestHandler(type, resource, params);
};
export default uploadImage;
