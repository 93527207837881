import React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';

import CardWithIcon from './CardWithIcon';

const UsersWidget = ({users, members, practitioners}) => {
    return (
        <CardWithIcon
            to="/users"
            icon={PeopleIcon}
            title="Users"
            subtitle={users}
        >
            <ul><li>Members: {members}</li><li>Practitioners: {practitioners}</li></ul>
        </CardWithIcon>
    );
};

export default UsersWidget;