import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField
} from 'react-admin';
import { PostPagination } from './Components/pagination'

export const PurchaseList = props => {
    return (
        <List {...props} bulkActionButtons={false} pagination={<PostPagination />}>
            <Datagrid>
                <TextField source="purchased_content_id.content_title" label="Content"></TextField>
                <TextField source="purchased_content_owner.u_prac_username" label="Content Owner (Practitioner Username)"></TextField>
                <TextField source="purchased_user.u_username" label="Purchased By (Member Username)"></TextField>
                <DateField source="created_at" label="Date of Purchase"></DateField>
                <NumberField source="purchased_amount" label="Amount ($)" options={{ minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
            </Datagrid>
        </List>
    )    
}