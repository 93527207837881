import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListAvatar from './ListAvatar'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    }
}));
const FullNameField= ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            <ListAvatar
                record={record}
                size={size}
            />
            {record.u_fullname}
        </div>
    ) : null;
};

FullNameField.defaultProps = {
    label: 'Full Name'
}
export default FullNameField