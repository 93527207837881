import React, { useState, useEffect } from 'react';
import { Title } from 'react-admin';
import baseUrl from '../config'
import Welcome from './Welcome'
import UsersWidget from './UsersWidget';
import ContentWidget from './ContentWidget'
const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {   
    const [userStat, setUserStat] = useState({})
    const [contentStat, setContentStat] = useState({})
    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        await fetch(`${baseUrl}/api/admin/dashboard`, {method: 'GET', headers: {                 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
             }})
            .then(res => res.json())
            .then(data => {
                const {users, members, practitioners} = data.user;
                setUserStat(state => ({
                    ...state,
                    users,
                    members,
                    practitioners
                }));
                const {contents, free, premium, exclusive} = data.content;
                setContentStat(state => ({
                    ...state,
                    contents,
                    free,
                    premium,
                    exclusive
                }));
            })
            .catch((e) => {
                console.log('Error: ', e.message)
            });
    } 
    useEffect(() => {
        fetchUsers()
    }, [])
    return (
        <>
            <Title title="Dashboard" />
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <UsersWidget {...userStat} />
                        <Spacer />
                        <ContentWidget {...contentStat} />
                    </div>
                </div>
            </div>    
        </>
    )
}

export default Dashboard;