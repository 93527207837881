import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const ListAvatar = ({ record, size }) => (
    <Avatar
        src={`${record.u_image_url}?size=${size}x${size}`}
        size={size}
        style={{
          width: size,
          height: size
        }}
    />
);

ListAvatar.defaultProps = {
    size: 30,
};

export default ListAvatar;