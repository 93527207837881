import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  DisabledInput,
  TextInput,
  BooleanInput,
  Filter,
  Toolbar,
  SelectInput,
  SaveButton,
  LongTextInput,
  required,
  number
} from "react-admin";
import { PostPagination } from './Components/pagination';
const validateOrder = number();

export const QuestionsEdit = props => {
  return (
    <Edit title={"Edit Assesment Question"} {...props}>
      <SimpleForm toolbar={<QuestionsEditToolbar />}>
        <DisabledInput label="Id" source="id" />
        <LongTextInput source="question" validate={required()} />
        <TextInput source="question_order" label="Order"  validate={validateOrder}/>
        <BooleanInput source="question_active" />
      </SimpleForm>
    </Edit>
  );
};

const QuestionsEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const QuestionsFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="questionnaire_type"
      choices={[
        { id: "life", name: "life" },
        { id: "therapy", name: "therapy" },
        { id: "ace-trauma", name: "ace-trauma" }
      ]}
      alwaysOn
    />
  </Filter>
);

export const QuestionsList = props => {
  return (
    <List
      {...props}
      filters={<QuestionsFilter />}
      bulkActionButtons={false}
      pagination={<PostPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="question" label="Question"/>
        <TextField source="questionnaire_type" label="Type" />
        <TextField source="questions_required" label="Required"/>
        <TextField source="question_order" label="Order" />
        <TextField source="question_active" label="Active" />
      </Datagrid>
    </List>
  );
};