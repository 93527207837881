import React from 'react';
import { TextField, List, Datagrid } from "react-admin";
import { PostPagination } from './Components/pagination';
export const Feedbacklist = props => {
    return (
        <List {...props} bulkActionButtons={false} pagination={<PostPagination />}>
            <Datagrid>
                <TextField source="feedback_sender_name" label="Sender Name"></TextField>
                <TextField source="feedback_sender_email" label="Sender Email"></TextField>
                <TextField source="feedback_type" label="Feedback Type"></TextField>
                <TextField source="feedback_comment" label="Comment"></TextField>
            </Datagrid>
        </List>
    )
}