import React from 'react'
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        background: '#7933f2',
        color: "#fff",
        padding: 20,
        marginTop: '16px',
        marginBottom: '1em',
    }
})

const Welcome = () => {
    const classes = useStyles()
    return (
        <>            
            <Card className={classes.root}>          
                <CardContent>Welcome to the GuideTrue admin dashboard</CardContent>
            </Card>
        </>
    )
}

export default Welcome