let baseUrl;
//if(process.env.NODE_ENV === "production"){
  //  baseUrl = "https://dev-api.guidetrue.com"
//}
//if(process.env.NODE_ENV === "staging"){
    baseUrl = "https://api.guidetrue.com"
//}
//else{
 ///   baseUrl ="http://localhost:7000"
//}
export default baseUrl;
