import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  DisabledInput,
  TextInput,
  Filter,
  Create,
  ImageInput,
  ImageField,
  required,
  number
} from "react-admin";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { PostPagination } from './Components/pagination';
import baseUrl from './config'

const validateOrder = number();

export const FocusEdit = props => {
  return (
    <Edit title={"Edit Focus"} {...props}>
      <SimpleForm >
        <DisabledInput label="Id" source="id" />
        <TextInput source="focus" label="Focus" validate={required()} />
        <TextInput
          source="focus_display_name"
          label="Display Name"
          validate={required()}
        />
        <ImageInput source="focus_image_url" label="Focus Image" accept="image/*" validate={required()}>
          <ImageField source="src" label="Image" />
        </ImageInput>
        <TextInput source="focus_display_order" label="Order" validate={validateOrder} />
      </SimpleForm>
    </Edit>
  );
};


const FocusFilter = props => (
  <Filter {...props}>
 
  </Filter>
);

export const FocusCreate = props =>{
  return(
    <Create {...props} title={"Create Focus"}>
      <SimpleForm>
        <TextInput source="focus" label="Focus" />
        <TextInput source="focus_display_name" label="Display Name" />
        <TextInput source="focus_display_order" label="Order" />
        <ImageInput source="focus_image_url" label="Focus Image" accept="image/*">
          <ImageField source="src" label="Image" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}

export const FocusList = props => {
  const [state, setState] = React.useState({
    isControlled: false,
  });
  const fetchSettings = async () => {
    const token = localStorage.getItem('token');
    await fetch(`${baseUrl}/api/admin/focus_settings`,
      {method: 'GET', headers: {                 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }})
      .then(res => res.json())
      .then(data => {
          setState(data)
      })
      .catch((e) => {
          console.log('Error: ', e.message)
      });
  }
  React.useEffect(() => {
        fetchSettings()
  }, [])
  const setFocusConfig = async data => {
    const token = localStorage.getItem('token');
    await fetch(`${baseUrl}/api/admin/focus_settings`,
      { method: 'PUT',
        headers: {                 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(data => {
          setState(data)
      })
      .catch((e) => {
          console.log('Error: ', e.message)
      });
  }
  const handleChange = event => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    setFocusConfig({[event.target.name]: event.target.checked })
  };
  return (
    <div style={{display: 'flex'}}>
    <div style={{flex: 3, marginRight: '0.5em'}} >
    <List {...props} filters={<FocusFilter />} bulkActionButtons={false} pagination={<PostPagination />}>
      <Datagrid rowClick="edit">
        <TextField source="focus" label="Focus" />
        <TextField source="focus_display_name" label="Display Name" />
        <ImageField source="focus_image_url" label="Image" sortable={false}/>
        <TextField source="focus_display_order" label="Order" />
        <TextField source="contentsCount" label="Contents Count" />
      </Datagrid>
    </List>
    </div>
    <div style={{flex: 1, marginLeft: '0.5em'}} >
    <h2>Focus Settings</h2>
    <FormGroup row>
      <FormControlLabel    
        value="start"    
        label="Focus display controlled by admin"
        control={<Switch checked={state.isControlled} onChange={handleChange} color="primary" name="isControlled" inputProps={{ 'aria-label': 'primary checkbox' }} />}
        labelPlacement="start"
      />
    </FormGroup>
    </div>
    </div>
  );
};
