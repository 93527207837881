import React from 'react';
import {
    TextField,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    DisabledInput,
    TextInput,
    Create
} from "react-admin";
import { PostPagination } from './Components/pagination';
export const CulturalHeritageList = props => {
    return (
        <List {...props} bulkActionButtons={false} pagination={<PostPagination />}>
            <Datagrid rowClick="edit">
                <TextField source="name" label="name"></TextField>
            </Datagrid>
        </List>
    )
}

export const CulturalHeritageEdit = props => {
    return (
        <Edit title={"Edit Cultural heritage"} {...props}>
            <SimpleForm>
                <DisabledInput source="id" label="Id" />
                <TextInput source="name" label="name" />
            </SimpleForm>
        </Edit>
    )
}

export const CulturalHeritageCreate = props => {
    return (
        <Create {...props} title={"Create Cultural heritage"}>
            <SimpleForm>
                <TextInput source="name" label="name" />
            </SimpleForm>
        </Create>
    )
}