import React from "react";
import {
    TextField,
    Datagrid,
    List,
    BooleanField,
    Show,
    SimpleShowLayout,
    BooleanInput,
    SimpleForm,
} from "react-admin";
import { PostPagination } from './Components/pagination';
import baseUrl from './config'


const handleClick = (props) => {
    const token = localStorage.getItem("token");
    fetch(`${baseUrl}/api/admin/practitioners/feature_practitioner/${props.id}`,
        {
            method: 'PUT',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            }),
            body: null
        })
}

export const PractitionersList = props => {
    return (
        <List {...props} bulkActionButtons={false} pagination={<PostPagination />}>
            <Datagrid rowClick="show">
                <TextField source="p_business_name" label="Business name"></TextField>
                <TextField source="p_user.u_username" label="User"></TextField>
                <BooleanField source="p_featured" label="Practitioner Featured"></BooleanField>
            </Datagrid>
        </List>
    )
}

export const PractitionerShow = props => {
    return (
        <Show title={"Practitioner Details"} {...props}>
            <SimpleShowLayout>
                <TextField source="p_business_name" label="Business name"></TextField>
                <TextField source="p_user.u_username" label="User"></TextField>
                <TextField source="p_address" label="Address"></TextField>
                <TextField source="p_phone_number" label="Phone"></TextField>
                <SimpleForm toolbar={false}>
                    <BooleanInput onChange={() => handleClick(props)} source="p_featured" label="Feature Practitioner"/>
                </SimpleForm>
            </SimpleShowLayout>
        </Show>
    )
}